<template>
  <div>
    <v-alert
      rounded
      v-if="running"
      icon="mdi-sync"
      prominent
      color="warning"
      border="left"
    >
      <v-row>
        <v-col>
          Aktueller Import: {{ formatDate(startDate) }}, {{ startTime }} ({{
            startedBy.code
          }})
        </v-col>
        <v-col>
          <v-progress-linear
            color="primary"
            :value="(100 * progress) / progressMax"
          ></v-progress-linear>
          {{ progressText }}
        </v-col>
      </v-row>
    </v-alert>
    <v-alert
      v-else
      prominent
      dark
      icon="mdi-history"
      border="left"
      color="success"
    >
      <v-row align="center">
        <v-col class="grow">
          Letzter Import: {{ formatDate(startDate) }}, {{ startTime }} ({{
            startedBy.code
          }})
        </v-col>
        <v-col class="shrink">
          <v-btn text color="white" @click="execute"
            >Änderungen ausführen
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-toolbar flat class="mb-4">
      <v-chip-group v-model="filterLevel" column multiple mandatory>
        <v-chip
          filter
          v-for="filter in filterLevels"
          :color="getChipColor(filter)"
          :value="filter"
          :key="filter"
        >
          {{ filter }}
        </v-chip>
      </v-chip-group>
    </v-toolbar>
    <v-card class="mt-2">
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="log"
        :items-per-page="-1"
        :search="search"
        disable-sort
      >
        <template v-slot:item.date="{ item }">
          {{ formatDateShort(item.date) }}
        </template>
        <template v-slot:item.level="{ item }">
          <v-chip :color="getChipColor(item.level)">{{ item.level }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ChangesExecute",
  props: ["search"],
  data: () => ({
    loading: false,
    running: false,
    progress: 0,
    progressMax: 10,
    progressText: "",
    startDate: "",
    startTime: "",
    startedBy: { name: "leer" },
    filterLevel: ["Info", "Warnung", "Fehler", "Erfolg"],
    filterLevels: ["Info", "Warnung", "Fehler", "Erfolg"],
    log: [],
  }),
  computed: {
    headers() {
      return [
        { text: "Datum", value: "date" },
        { text: "Zeit", value: "time" },
        {
          text: "Level",
          value: "level",
          filter: (value) => {
            return this.filterLevel.includes(value);
          },
        },
        { text: "Nachricht", value: "message" },
      ];
    },
  },
  methods: {
    async execute() {
      await this.apiPost({ resource: "change/execute", data: {} });
      await this.getData();
    },
    async getData() {
      this.loading = true;
      const result = await this.apiList({ resource: "change/execute" });
      this.loading = false;
      this.running = result.running;
      this.log = result.log;
      this.startDate = result.startDate;
      this.startTime = result.startTime;
      if (result.startedBy) {
        this.startedBy = result.startedBy;
      }
      if (this.running) {
        this.progress = result.progress;
        this.progressMax = result.progressMax;
        this.progressText = result.progressText;
        setTimeout(() => {
          this.getData();
        }, 2000);
      }
    },
    getChipColor(level) {
      if (level === "Info") {
        return "info";
      }
      if (level === "Warnung") {
        return "warning";
      }
      if (level === "Fehler") {
        return "error";
      }
      if (level === "Erfolg") {
        return "success";
      }
    },
    formatDate(value) {
      const date = new Date(value);
      if (date == "Invalid Date") {
        return "–";
      }
      return date.toLocaleString("de-CH", {
        dateStyle: "full",
      });
    },
    formatDateShort(value) {
      const date = new Date(value);
      if (date == "Invalid Date") {
        return "–";
      }
      return date.toLocaleString("de-CH", {
        dateStyle: "medium",
      });
    },
  },
  async created() {
    this.getData();
  },
});
</script>
